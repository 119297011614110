import styled from 'styled-components';

export const RequirementsWrapper = styled.div``;

export const RequirementWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[1]};
`;

export const RequirementIconWrapper = styled.div``;

export const RequirementDescriptionWrapper = styled.div`
  margin-top: -${({ theme }) => theme.spacing[1]};
`;
