import { ErrorsType } from 'interfaces';
import {
  useSigninCheckMfaMutation,
  useSigninResendMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

export const useMultiFactorAuth = () => {
  const [errors, setErrors] = useState<ErrorsType>();
  const [CheckMfa, { loading }] = useSigninCheckMfaMutation();
  const [resend] = useSigninResendMutation();
  const { login } = useAuth();

  const router = useRouter();
  const method = router.query?.method?.toString();
  // no need to validate method in this hook as BE will catch bad method in calls below

  const resetErrors = (code: number) =>
    setErrors((errors) => errors?.filter((error) => error.code !== code));

  const onSubmit = async (values: { digitCodes?: string }) => {
    try {
      const result = await CheckMfa({
        variables: {
          data: { digitCodes: values.digitCodes || '', method: method || '' },
        },
      });
      console.error('CheckMfa Result', result);
      if (
        result.data?.authCheckMfaCode.success &&
        typeof result.data.authCheckMfaCode.userId == 'string'
      ) {
        const userId = result.data.authCheckMfaCode.userId;
        await login({ userId });
      } else {
        console.error('false or userId not string', result);
        const errors = result.data?.authCheckMfaCode.errors;
        setErrors(errors);
        setSentryErrors(errors);
      }
    } catch (e) {
      console.error('CheckMfa Result Error', e);
      setErrors([formatError(e)]);
      setSentryErrors(e);
    }
  };

  const resendRequest = async () => {
    try {
      const result = await resend({
        variables: {
          data: { method: method || '' },
        },
      });
      if (result.data?.authResendMfaCode.success) {
        setErrors([]);
      } else {
        const errors = result.data?.authResendMfaCode?.errors;
        setErrors(errors);
        setSentryErrors(errors);
      }
    } catch (e) {
      setErrors([formatError(e)]);
      setSentryErrors(e);
    }
  };

  return {
    loading,
    errors,
    onSubmit,
    resetErrors,
    resendRequest,
    method,
  };
};
