import { Svg } from './styles';

interface MinusIconProps {
  width?: string;
  height?: string;
  color?: string;
}

export const MinusIcon = ({
  height = '24',
  width = '24',
  color = '#424249',
}: MinusIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.2875 11.2875C5.47917 11.0958 5.71667 11 6 11H11H13H18C18.2833 11 18.5208 11.0958 18.7125 11.2875C18.9042 11.4792 19 11.7167 19 12C19 12.2833 18.9042 12.5208 18.7125 12.7125C18.5208 12.9042 18.2833 13 18 13H13H11H6C5.71667 13 5.47917 12.9042 5.2875 12.7125C5.09583 12.5208 5 12.2833 5 12C5 11.7167 5.09583 11.4792 5.2875 11.2875Z"
      fill={color}
    />
  </Svg>
);
