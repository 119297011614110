import styled from 'styled-components';

export const MultiFactorResendWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing[1]};
  button {
    height: auto;
  }
`;

export const MessageWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacing[1]};
  align-items: center;
  button {
    white-space: nowrap;
    font-size: ${({ theme }) => theme.fonts.size.sm};
    text-decoration: underline;
  }
`;
