import { ErrorsType } from 'interfaces';
import {
  useUserOnboardingCheckMfaCodeMutation,
  useUserOnboardingResendMfaCodeMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

// const KEY_FORM = 'resetPassword2fa';

export const useOnboardingMultiFactorAuth = () => {
  const [errors, setErrors] = useState<ErrorsType>();

  const [onboardingCheckMfaCode, { loading: checkLoading }] =
    useUserOnboardingCheckMfaCodeMutation();
  const [onboardingResendMfaCode, { loading: resendLoading }] =
    useUserOnboardingResendMfaCodeMutation();

  const {
    state: { user },
  } = useAuth();

  const router = useRouter();
  const mfaMethod = (router.query?.method || '').toString();

  const resetErrors = (code: number) =>
    setErrors((errors) => errors?.filter((error) => error.code !== code));

  const onSubmit = useCallback(
    async (data: { [x: string]: string }) => {
      const digitCodes = data.digitCodes || '';

      try {
        const result = await onboardingCheckMfaCode({
          variables: { data: { digitCodes, method: mfaMethod } },
        });
        if (result.data?.onboardingCheckMfaCode.success) {
          router.push({
            pathname: '/auth/onboarding-password',
            query: { from: '/auth/2-fa-onboarding' },
          });
        } else {
          const errors = result.data?.onboardingCheckMfaCode.errors;
          setErrors(errors);
          setSentryErrors(errors, { userId: user?.userId });
        }
      } catch (e) {
        setErrors([formatError(e)]);
        setSentryErrors(e, { userId: user?.userId });
      }
    },
    [onboardingCheckMfaCode, mfaMethod, router, user?.userId]
  );

  const resendRequest = useCallback(async () => {
    try {
      const result = await onboardingResendMfaCode({
        variables: { data: { method: mfaMethod } },
      });
      if (result.data?.onboardingResendMfaCode.success) {
        setErrors([]);
      } else {
        const errors = result.data?.onboardingResendMfaCode?.errors;
        setErrors(errors);
        setSentryErrors(errors, { userId: user?.userId });
      }
    } catch (e) {
      setErrors([formatError(e)]);
      setSentryErrors(e, { userId: user?.userId });
    }
  }, [onboardingResendMfaCode, mfaMethod, user?.userId]);

  const loading = checkLoading || resendLoading;

  return {
    onSubmit,
    resendRequest,
    loading,
    errors,
    resetErrors,
    method: mfaMethod,
  };
};
