import theme from 'stories/theme';
import styled from 'styled-components';

export const InstructionText = styled.div`
  color: ${theme.colors.grey[400]};
  font-family: ${theme.fonts.family.secondary};
  font-size: ${theme.fonts.size.sm};
  font-weight: ${theme.fonts.weight.normal};
  line-height: 150%;
`;
