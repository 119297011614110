import { Requirements } from 'modules/auth/components/Requirements';
import { Controller } from 'react-hook-form';
import { renderFormInputsFields } from 'utils/helpers/renderFormInputFields/renderFormInputFields';

import { AuthPageFieldsProps } from '../types';
import { getInputsFieldsConfig, getRequirements } from './config';

export default function OnboardingPassword({ methods }: AuthPageFieldsProps) {
  const inputsFieldsConfig = getInputsFieldsConfig({ methods });
  const password = methods.watch('password') || '';
  const requirements = getRequirements(password);

  return (
    <>
      {inputsFieldsConfig.map((inputFieldConfig) => (
        <Controller
          key={inputFieldConfig.name}
          name={inputFieldConfig.name}
          rules={inputFieldConfig.rules}
          control={methods.control}
          render={({ field }) =>
            renderFormInputsFields({
              inputFieldConfig,
              field,
              methods,
            })
          }
        />
      ))}
      <Requirements requirements={requirements} />
    </>
  );
}
