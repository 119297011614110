import { Body } from 'stories/Typography';
import { useTheme } from 'styled-components';
import { CheckIcon } from 'ui/Icons/CheckIcon';
import { MinusIcon } from 'ui/Icons/MinusIcon';

import {
  RequirementDescriptionWrapper,
  RequirementIconWrapper,
  RequirementsWrapper,
  RequirementWrapper,
} from './styles';

export type RequirementType = {
  type: string;
  label: string;
  isValid: boolean;
};

interface RequirementsProps {
  requirements: RequirementType[];
}

export const Requirements = ({ requirements }: RequirementsProps) => {
  const theme = useTheme();
  return (
    <RequirementsWrapper>
      {requirements.map((requirement) => (
        <RequirementWrapper key={requirement.type}>
          <RequirementIconWrapper>
            {requirement.isValid ? (
              <CheckIcon width="16px" color={theme.colors.green[500]} />
            ) : (
              <MinusIcon width="16px" color={theme.colors.grey[400]} />
            )}
          </RequirementIconWrapper>
          <RequirementDescriptionWrapper>
            <Body size="small" color={theme.colors.grey[400]}>
              {requirement.label}
            </Body>
          </RequirementDescriptionWrapper>
        </RequirementWrapper>
      ))}
    </RequirementsWrapper>
  );
};
