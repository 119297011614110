import { DefaultTheme } from 'styled-components';
import { MethodsType } from 'utils/helpers/renderFormInputFields/types';

interface ConfigType {
  type: string;
  name: string;
  label?: string;
  placeholder?: string;
  size?: string;
  rules: object;
  color?: string;
  labels?: { type: string; text: string }[];
  options?: {
    value: string;
    variant: string;
    color: string;
    labels: { type: string; text: string }[];
  }[];
  triggerOtherFields?: string[];
}

export const fieldPrefix = 'mfaOption';
export const getInputsFieldsConfig = (
  offered: string[],
  methods: MethodsType,
  theme: DefaultTheme
) => {
  const phoneOffered = offered.includes('phone');
  const emailOffered = offered.includes('email');

  const selectedMethod = methods.watch('mfaOption.method');
  const registerPhone = Boolean(methods.watch('mfaOption.registerPhone'));

  const radioBoxOptions = () => ({
    type: 'RadioBox',
    mode: 'dark',
    name: `${fieldPrefix}.method`,
    options: [
      {
        value: 'phone',
        variant: 'standard',
        color: theme.colors.grey[0],
        labels: [
          {
            type: 'option',
            text: 'Send to my mobile number',
          },
        ],
      },
      {
        value: 'email',
        variant: 'standard',
        color: theme.colors.grey[0],
        labels: [
          {
            type: 'option',
            text: 'Send to my email address',
          },
        ],
      },
    ],
    rules: {
      required: 'Required',
    },
    triggerOtherFields: ['mfaOption.phone'],
  });

  const divider = () => ({
    type: 'Divider',
    name: 'divider',
    rules: {},
    color: theme.colors.grey[500],
  });

  const registerPhoneCheckBoxOption = () => ({
    type: 'Checkbox',
    name: `${fieldPrefix}.registerPhone`,
    color: theme.colors.grey[0],
    mode: 'dark',
    labels: [
      {
        type: 'option',
        text: `I'd like to include my mobile number as an additional MFA method`,
      },
    ],
    rules: {},
    triggerOtherFields: ['mfaOption.phone'],
  });

  const phoneOption = () => ({
    type: 'PhoneInput',
    name: `${fieldPrefix}.phone`,
    label: 'Mobile Number',
    placeholder: 'Enter mobile number',
    size: 'large',
    rules: {
      validate: (value: string | undefined) => {
        const currentSelected = methods.getValues('mfaOption.method');
        const currentRegisterPhone = Boolean(
          methods.getValues('mfaOption.registerPhone')
        );

        const required =
          (phoneOffered && !emailOffered) ||
          currentSelected === 'phone' ||
          (currentSelected === 'email' && currentRegisterPhone);

        const numberRegExp = new RegExp(
          '((?:\\+|00)[17](?: |\\-)?|(?:\\+|00)[1-9]\\d{0,2}(?: |\\-)?|(?:\\+|00)1\\-\\d{3}(?: |\\-)?)?(0\\d|\\([0-9]{3}\\)|[1-9]{0,3})(?:((?: |\\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\\-)[0-9]{3}(?: |\\-)[0-9]{4})|([0-9]{7}))'
        );

        const validNumber =
          value !== undefined ? numberRegExp.test(value) : false;

        if (required && !value) return 'Required';
        if (value)
          return validNumber ? true : 'Please enter a valid Mobile Number';
      },
    },
  });

  const agreementCheckBoxOption = () => {
    let textString = '';
    if (emailOffered && !phoneOffered) {
      textString = 'email';
    } else if (phoneOffered && !emailOffered) {
      textString = 'phone';
    } else {
      if (selectedMethod === 'phone') {
        textString = 'email and phone';
      } else {
        if (registerPhone) {
          textString = 'email and phone';
        } else {
          textString = 'email';
        }
      }
    }

    return {
      type: 'Checkbox',
      name: `${fieldPrefix}.agreement`,
      color: theme.colors.grey[0],
      mode: 'dark',
      labels: [
        {
          type: 'option',
          text: `I agree to receive communications at the ${textString} specified above.`,
        },
      ],
      rules: {
        required: 'Required',
      },
    };
  };

  const config: ConfigType[] = [];

  if (offered.length === 0) {
    return config;
  } else if (phoneOffered && !emailOffered) {
    config.push(phoneOption(), divider(), agreementCheckBoxOption());
  } else if (emailOffered && !phoneOffered) {
    config.push(agreementCheckBoxOption());
  } else {
    config.push(radioBoxOptions(), divider());

    if (selectedMethod === 'phone') {
      config.push(phoneOption());
    } else {
      config.push(registerPhoneCheckBoxOption());

      if (registerPhone) config.push(phoneOption());
    }

    config.push(divider(), agreementCheckBoxOption());
  }

  return config;
};
