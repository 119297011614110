import styled from 'styled-components';

export const ForgotPasswordButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  flex: 1;
  margin-top: -${({ theme }) => theme.spacing[2]};
  margin-bottom: -${({ theme }) => theme.spacing[4]};
`;
