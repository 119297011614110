import styled from 'styled-components';

export const ForgotPasswordCheckEmailDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14pt;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.grey[100]};
  margin-top: unset;
  margin-bottom: 3rem;
`;
