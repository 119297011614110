import styled from 'styled-components';

export const Badge = styled.div`
  display: block;
  line-height: 1.33;
  font-size: ${({ theme }) => theme.fonts.size.sm};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-family: ${({ theme }) => theme.fonts.family.secondary};
  color: ${({ theme }) => theme.colors.grey[400]};
  text-align: center;
  a text {
    text-decoration: underline;
  }
`;
