import { PinInput } from 'stories/InputFields';

import { useOnboardingMultiFactorAuth } from '../hooks/useOnboardingMultiFactorAuth';
import MultiFactorResend from '../MultiFactorResend';
import { AuthPageFieldsProps } from '../types';

export const OnboardingMultiFactorAuth = ({ methods }: AuthPageFieldsProps) => {
  const { resendRequest } = useOnboardingMultiFactorAuth();

  const handlePinChange = (pin: string) => {
    methods.setValue('digitCodes', pin, { shouldValidate: true });
  };

  return (
    <>
      <PinInput mode="light" width="100%" onChange={handlePinChange} />
      <MultiFactorResend resendRequest={resendRequest} />
    </>
  );
};
