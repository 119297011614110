import { PinInput } from 'stories/InputFields';

import { useResetPasswordMultiFactorAuth } from '../hooks/useResetPasswordMultiFactorAuth';
import MultiFactorResend from '../MultiFactorResend';
import { AuthPageFieldsProps } from '../types';

export const ResetPasswordMultiFactorAuth = ({
  methods,
}: AuthPageFieldsProps) => {
  const { resendRequest } = useResetPasswordMultiFactorAuth();

  const handlePinChange = (pin: string) => {
    methods.setValue('digitCodes', pin, { shouldValidate: true });
  };

  return (
    <>
      <PinInput mode="light" width="100%" onChange={handlePinChange} />
      <MultiFactorResend resendRequest={resendRequest} />
    </>
  );
};
