import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { renderFormInputsFields } from 'utils/helpers/renderFormInputFields/renderFormInputFields';

import { useResetSendMfa } from '../hooks/useResetSendMfa';
import { AuthPageFieldsProps } from '../types';
import { getInputsFieldsConfig } from './config';

export const ResetSendMfa = ({ methods }: AuthPageFieldsProps) => {
  const theme = useTheme();
  const { methodsValid, availableMethods, preferredMethod } = useResetSendMfa();
  const selectedMethod = methods.watch('resetSendMfaOption.method');

  // once preferred method is loaded/valid, set radio button start value & trigger validation to enable submit
  useEffect(() => {
    if (!preferredMethod) return;
    if (!methodsValid) return;
    if (!selectedMethod) {
      methods.setValue('resetSendMfaOption.method', preferredMethod);
      methods.trigger('resetSendMfaOption.method');
    }
  }, [preferredMethod, selectedMethod, methodsValid]);

  // if methods are invalid top-level page will show error and this will return []
  const inputsFieldsConfig = getInputsFieldsConfig(
    methodsValid,
    availableMethods,
    theme
  );

  return (
    <>
      {inputsFieldsConfig.map((inputFieldConfig) => (
        <Controller
          key={inputFieldConfig.name}
          name={inputFieldConfig.name}
          rules={inputFieldConfig.rules}
          control={methods.control}
          render={({ field }) =>
            renderFormInputsFields({
              inputFieldConfig,
              field,
              methods,
            })
          }
        />
      ))}
    </>
  );
};
