import { ForgotPasswordCheckEmailDescription } from './styles';

export const ForgotPasswordCheckEmail = () => (
  <>
    <ForgotPasswordCheckEmailDescription>
      If that email address exists in our system we will send a link enabling
      you to reset your password.
    </ForgotPasswordCheckEmailDescription>
    <ForgotPasswordCheckEmailDescription>
      Please check your email.
    </ForgotPasswordCheckEmailDescription>
  </>
);
