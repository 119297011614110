import { DefaultTheme } from 'styled-components';

export const fieldPrefix = 'sendMfaOption';

export const getInputsFieldsConfig = (
  methodsValid: boolean,
  availableMethods: string[],
  theme: DefaultTheme
) => {
  if (methodsValid) {
    return [
      {
        type: 'RadioBox',
        name: `${fieldPrefix}.method`,
        options: availableMethods.map((availableMethod) => ({
          value: availableMethod,
          variant: 'standard',
          color: theme.colors.grey[0],
          labels: [
            {
              type: 'option',
              text: `Send to my ${
                availableMethod === 'phone' ? 'mobile number' : 'email address'
              }`,
            },
          ],
        })),
        rules: {
          required: 'Required',
        },
      },
    ];
  }

  return [];
};
