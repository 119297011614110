import { RequirementType } from 'modules/auth/components/Requirements';
import { MethodsType } from 'utils/helpers/renderFormInputFields/types';

interface GetInputsFieldsConfigParams {
  methods?: MethodsType;
}

export const getInputsFieldsConfig = ({
  methods,
}: GetInputsFieldsConfigParams) => [
  {
    type: 'PasswordInput',
    name: `password`,
    label: 'Password',
    placeholder: 'Create your password',
    size: 'large',
    rules: {
      required: 'Password is required',
      minLength: {
        value: 8,
        message: 'Password must have at least 8 characters',
      },
      maxLength: {
        value: 16,
        message: 'Password must have no more than 16 characters',
      },
      pattern: {
        value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&*!])/,
        message:
          'Password must include at least one number, one uppercase letter, one lowercase letter, and one special character (@ # $ % & * !)',
      },
    },
  },
  {
    type: 'PasswordInput',
    name: `passwordConfirm`,
    label: 'Re-enter password',
    placeholder: 'Re-enter your password',
    size: 'large',
    rules: {
      required: 'Password confirmation is required',
      validate: {
        matchesPreviousPassword: (value: string) => {
          const password = methods?.getValues('password');
          return password === value || 'Passwords must match';
        },
      },
    },
  },
];

export const getRequirements = (password: string): RequirementType[] => [
  {
    type: 'length',
    label: '8-16 characters',
    isValid: password.length >= 8 && password.length <= 16,
  },
  {
    type: 'number',
    label: 'At least 1 number',
    isValid: /\d/.test(password),
  },
  {
    type: 'lowercase',
    label: 'At least 1 lowercase letter',
    isValid: /[a-z]/.test(password),
  },
  {
    type: 'uppercase',
    label: 'At least 1 uppercase letter',
    isValid: /[A-Z]/.test(password),
  },
  {
    type: 'specialCharacter',
    label: 'At least 1 special character (@ # $ % & * !)',
    isValid: /[@#$%&*!]/.test(password),
  },
];
