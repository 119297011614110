import { EmailInput } from 'stories/InputFields';
import { emailSchema } from 'stories/InputFields/schema';

import { AuthPageFieldsProps } from '../types';

export const ForgotPassword = ({ methods }: AuthPageFieldsProps) => (
  <>
    <EmailInput
      {...methods.register('email', emailSchema)}
      size="large"
      label="Email"
      placeholder="Enter your email address"
      state={methods.formState.errors.email ? 'error' : 'default'}
      errorMessage={methods.formState.errors.email?.message as string}
    />
  </>
);
