import { PUBLIC_IMAGE_BUCKET } from 'config';
import styled from 'styled-components';
import Container from 'ui/Container';

interface WrapperProps {
  client: string;
}

export const Wrapper = styled.div<WrapperProps>`
  background: ${({ client, theme }) =>
    client === 'solyco'
      ? `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  url(https://${PUBLIC_IMAGE_BUCKET}.s3.amazonaws.com/default_bg.jpg)`
      : theme.colors.grey[700]};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing[12]};
  flex: 1;
`;

export const SubWrapper = styled(Container)`
  max-width: 375px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Badge = styled.div`
  text-align: center;
  display: block;
  line-height: 1.33;
  font-size: ${({ theme }) => theme.spacing[3.5]};
  font-weight: ${({ theme }) => theme.fonts.weight.medium};
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.grey[400]};
  a text {
    text-decoration: underline;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${({ theme }) => theme.colors.grey[400]};
  }
  gap: ${({ theme }) => theme.spacing[4]};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 1;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
  margin: ${({ theme }) => theme.spacing[6]} 0;
`;
