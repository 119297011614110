import { ErrorsType } from 'interfaces';
import {
  OnboardingConfigureContactMfaParam,
  useUserOnboardingConfigureContactMfaMutation,
} from 'interfaces/graphql.types';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

const validateOfferedMethods = (offeredParam: string[]) => {
  const validOffered: string[] = [];

  if (offeredParam.includes('email')) validOffered.push('email');
  if (offeredParam.includes('phone')) validOffered.push('phone');

  return validOffered;
};

const getMfaMethodForData = (
  radioButtonMethod: string | undefined,
  offeredMfaMethods: string[]
) => {
  const phoneOffered = offeredMfaMethods.includes('phone');
  const emailOffered = offeredMfaMethods.includes('email');

  // if only phone or email were available radio buttons did not render, use available method
  if (phoneOffered && !emailOffered) return 'phone';
  if (emailOffered && !phoneOffered) return 'email';

  // use method chosen in radio buttons
  return radioButtonMethod || '';
};

const includePhoneInData = (
  radioButtonMethod: string | undefined,
  phoneRegistered: boolean,
  offeredMfaMethods: string[]
) => {
  const phoneOffered = offeredMfaMethods.includes('phone');
  const emailOffered = offeredMfaMethods.includes('email');

  // same logic as phone required from form validation, if phone was required send it in data
  const includePhone =
    (phoneOffered && !emailOffered) ||
    radioButtonMethod === 'phone' ||
    (radioButtonMethod === 'email' && phoneRegistered);

  return includePhone;
};

export const useOnboardingConfigureContactMFA = () => {
  const [errors, setErrors] = useState<ErrorsType>(null);
  const [offeredMfaMethods, setOfferedMfaMethods] = useState<string[]>([]);
  const [paramMfaMethods, setParamMfaMethods] = useState<string[]>([]);
  const [configureContactMFA, { loading }] =
    useUserOnboardingConfigureContactMfaMutation();

  const router = useRouter();

  useEffect(() => {
    const offeredParam = router.query?.offered?.toString().split(',');
    const validOffered = validateOfferedMethods(offeredParam || []);

    setParamMfaMethods(offeredParam || []);
    setOfferedMfaMethods(validOffered);

    if (validOffered.length === 0) {
      const errors = [
        {
          code: 223,
          message:
            'Error 223 \n Offered MFA methods not set. Please contact administrator.',
          severity: 1,
        },
      ];

      setErrors(errors);
      setSentryErrors(errors);
    }
  }, [router]);

  const resetErrors = (code: number) =>
    setErrors((errors) => errors?.filter((error) => error.code !== code));

  const onSubmit = useCallback(
    async (values) => {
      try {
        const chosenMfaMethod = getMfaMethodForData(
          values.mfaOption.method,
          offeredMfaMethods
        );

        const usePhone = includePhoneInData(
          values.mfaOption.method,
          Boolean(values.mfaOption.registerPhone),
          offeredMfaMethods
        );

        // just to be safe, form validation should always prevent hitting this case
        if (!chosenMfaMethod || (usePhone && !values.mfaOption.phone)) return;

        const data: OnboardingConfigureContactMfaParam = {
          reCaptchaCode: values.reCaptchaCode,
          method: chosenMfaMethod,
          offeredMfaMethods: paramMfaMethods,
        };

        if (usePhone) data.phone = values.mfaOption.phone;

        const result = await configureContactMFA({
          variables: {
            data: data,
          },
        });

        if (result.data?.onboardingConfigureContactMFA.success) {
          setErrors(null);
          router.push({
            pathname: '/auth/2-fa-onboarding',
            query: {
              from: '/auth/onboarding',
              method: chosenMfaMethod,
            },
          });
        } else {
          const errors = result.data?.onboardingConfigureContactMFA.errors;
          setErrors(errors);
          setSentryErrors(errors);
        }
      } catch (e) {
        const formattedError = formatError(e);
        setErrors([formattedError]);
        setSentryErrors(e);
      }
    },
    [configureContactMFA, router, paramMfaMethods]
  );

  return {
    loading,
    errors,
    onSubmit,
    resetErrors,
    offeredMfaMethods,
  };
};
