import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import Checkbox from 'stories/Checkbox';
import { EmailInput } from 'stories/InputFields/EmailInput';
import { Input } from 'stories/InputFields/Input';
import { PasswordInput } from 'stories/InputFields/PasswordInput';
import { PhoneInput } from 'stories/InputFields/PhoneInput';
import { PinInput } from 'stories/InputFields/PinInput';
import { RadioBox } from 'stories/RadioBox';
import Divider from 'ui/Divider';

import { InstructionText } from './styles';
import { MethodsType } from './types';

interface RenderInputFieldsParams {
  inputFieldConfig: any;
  field: ControllerRenderProps<FieldValues, string>;
  methods: MethodsType;
}

export const renderFormInputsFields = ({
  inputFieldConfig: { type, ...config },
  field,
  methods,
}: RenderInputFieldsParams) => {
  const props = { ...config, ...field };
  switch (type) {
    case 'Input': {
      return <Input {...props} />;
    }

    case 'EmailInput': {
      return <EmailInput {...props} />;
    }

    case 'PasswordInput': {
      return <PasswordInput {...props} />;
    }

    case 'PhoneInput': {
      const errors = (methods.formState.errors.mfaOption || {}) as {
        [key: string]: any;
      };
      return (
        <PhoneInput
          {...props}
          state={errors?.phone ? 'error' : 'default'}
          errorMessage={errors?.phone?.message}
        />
      );
    }

    case 'PinInput': {
      return <PinInput {...props} />;
    }

    case 'Checkbox': {
      return (
        <Checkbox
          {...props}
          checked={field.value}
          onClick={(e) => {
            field.onChange(e.target.checked);
            if (props.triggerOtherFields)
              methods.trigger(props.triggerOtherFields);
          }}
        />
      );
    }

    case 'RadioBox': {
      return props.options.map((option: any) => (
        <RadioBox
          {...option}
          key={option?.value}
          mode={props.mode}
          variant={option?.variant}
          checked={field.value === option.value}
          value={option.value}
          onClick={() => {
            field.onChange(option.value);
            if (props.triggerOtherFields)
              methods.trigger(props.triggerOtherFields);
          }}
        />
      ));
    }

    case 'Instruction': {
      return <InstructionText>{props.text}</InstructionText>;
    }

    case 'Divider': {
      return <Divider color={props.color} />;
    }

    default:
      return <div>Type Not Found</div>;
  }
};
