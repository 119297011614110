import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { renderFormInputsFields } from 'utils/helpers/renderFormInputFields/renderFormInputFields';

import { useOnboardingConfigureContactMFA } from '../hooks/useOnboardingConfigureContactMFA';
import { AuthPageFieldsProps } from '../types';
import { getInputsFieldsConfig } from './config';

export const OnboardingConfigureContactMFA = ({
  methods,
}: AuthPageFieldsProps) => {
  const theme = useTheme();
  const { offeredMfaMethods } = useOnboardingConfigureContactMFA();
  const selectedMethod = methods.watch('mfaOption.method');

  useEffect(() => {
    if (offeredMfaMethods.length !== 2) return;
    if (offeredMfaMethods.length === 2 && !selectedMethod) {
      methods.setValue('mfaOption.method', 'phone');
      methods.trigger();
    }
  }, [selectedMethod, offeredMfaMethods]);

  const inputsFieldsConfig = getInputsFieldsConfig(
    offeredMfaMethods,
    methods,
    theme
  );

  return (
    <>
      {inputsFieldsConfig.map((inputFieldConfig) => (
        <Controller
          key={inputFieldConfig.name}
          name={inputFieldConfig.name}
          rules={inputFieldConfig.rules}
          control={methods.control}
          render={({ field }) =>
            renderFormInputsFields({
              inputFieldConfig,
              field,
              methods,
            })
          }
        />
      ))}
    </>
  );
};
