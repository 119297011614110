import { ErrorsType } from 'interfaces';
import { useSigninSendMfaMutation } from 'interfaces/graphql.types';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

// validate methods from params & ensure we don't show radio buttons for invalid choices
const validateMethods = (availableParam: string[], preferredParam: string) => {
  if (
    availableParam.includes('email') &&
    availableParam.includes('phone') &&
    (preferredParam === 'email' || preferredParam === 'phone')
  ) {
    return {
      methodsValid: true,
      available: ['phone', 'email'],
      preferred: preferredParam,
    };
  }

  return { methodsValid: false, available: [], preferred: '' };
};

export const useSendMfa = () => {
  const [errors, setErrors] = useState<ErrorsType>(null);
  const [methodsValid, setMethodsValid] = useState(false);
  const [availableMethods, setAvailableMethods] = useState<string[]>([]);
  const [preferredMethod, setPreferredMethod] = useState('');

  const [sendMfa, { loading }] = useSigninSendMfaMutation();

  const router = useRouter();

  useEffect(() => {
    const availableParam = router.query?.available?.toString().split(',');
    const preferredParam = router.query?.preferred?.toString();
    const {
      methodsValid: isValid,
      available,
      preferred,
    } = validateMethods(availableParam || [], preferredParam || '');

    setMethodsValid(isValid);
    setAvailableMethods(available);
    setPreferredMethod(preferred);

    if (!isValid) {
      const errors = [
        {
          code: 222,
          message:
            'Error 222 \n Available and preferred MFA methods not set. Please complete onboarding or contact administrator.',
          severity: 1,
        },
      ];
      setErrors(errors);
      setSentryErrors(errors);
    }
  }, [router]);

  const resetErrors = (code: number) =>
    setErrors((errors) => errors?.filter((error) => error.code !== code));

  const onSubmit = useCallback(
    async (values) => {
      if (!methodsValid || !values.sendMfaOption?.method) {
        const errors = [
          {
            code: 222,
            message:
              'Error 222 \n Available and preferred MFA methods not set. Please complete onboarding or contact administrator.',
            severity: 1,
          },
        ];
        setErrors(errors);
        setSentryErrors(errors);
        return;
      }

      try {
        const result = await sendMfa({
          variables: {
            data: {
              method: values.sendMfaOption.method,
            },
          },
        });

        if (result.data?.authSendMfaCode.success) {
          setErrors(null);
          router.push({
            pathname: '/auth/2-fa',
            query: {
              from: '/auth/send-2-fa',
              method: values.sendMfaOption.method,
            },
          });
        } else {
          const errors = result.data?.authSendMfaCode.errors;
          setErrors(errors);
          setSentryErrors(errors);
        }
      } catch (e) {
        const formattedError = formatError(e);
        setErrors([formattedError]);
        setSentryErrors(e);
      }
    },
    [sendMfa, router, methodsValid]
  );

  return {
    loading,
    errors,
    onSubmit,
    resetErrors,
    methodsValid,
    availableMethods,
    preferredMethod,
  };
};
