import { ErrorsType } from 'interfaces';
import {
  useUserResetPasswordMfaMutation,
  useUserResetPasswordResendMfaMutation,
} from 'interfaces/graphql.types';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { formatError, setSentryErrors } from 'utils/helpers/errorHandler';

export const useResetPasswordMultiFactorAuth = () => {
  const [errors, setErrors] = useState<ErrorsType>();
  const [checkMfa, { loading }] = useUserResetPasswordMfaMutation();
  const [resend] = useUserResetPasswordResendMfaMutation();

  const router = useRouter();
  const method = router.query?.method?.toString();

  const resetErrors = (code: number) =>
    setErrors((prevErrors) =>
      prevErrors?.filter((error) => error.code !== code)
    );

  const onSubmit = async (values: { digitCodes?: string }) => {
    try {
      const result = await checkMfa({
        variables: {
          data: { digitCodes: values.digitCodes || '', method: method || '' },
        },
      });

      if (result.data?.authResetCheckMfaCode.success) {
        router.push({
          pathname: '/auth/reset-password',
          query: { from: '/auth/2-fa-reset-password' },
        });
      } else {
        const errors = result.data?.authResetCheckMfaCode.errors;
        setErrors(errors);
        setSentryErrors(errors);
      }
    } catch (e) {
      setErrors([formatError(e)]);
      setSentryErrors(e);
    }
  };

  const resendRequest = async () => {
    try {
      const result = await resend({
        variables: {
          data: { method: method || '' },
        },
      });
      if (result.data?.authResetResendMfaCode?.success) {
        setErrors([]);
      } else {
        const errors = result.data?.authResetResendMfaCode?.errors;
        setErrors(errors);
        setSentryErrors(errors);
      }
    } catch (e) {
      setErrors([formatError(e)]);
      setSentryErrors(e);
    }
  };

  return {
    loading,
    errors,
    onSubmit,
    resetErrors,
    resendRequest,
    method,
  };
};
