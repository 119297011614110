export const emailSchema = {
  required: {
    message: 'Required',
    value: true,
  },
  pattern: {
    message: 'Email address format not valid yet',
    value: new RegExp(
      '^(("[\\w-\\s]+")|([\\w-]+(?:\\.[\\w-]+)*)|("[\\w-\\s]+")([\\w-]+(?:\\.[\\w-]+)*))(@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$)|(@\\[?((25[0-5]\\.|2[0-4][0-9]\\.|1[0-9]{2}\\.|[0-9]{1,2}\\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\\]?$)'
    ),
  },
  maxLength: 100,
};

export const phoneSchema = {
  required: {
    message: 'Required',
    value: true,
  },
  pattern: {
    message: 'Please enter a valid Mobile Number',
    value: new RegExp(
      '((?:\\+|00)[17](?: |\\-)?|(?:\\+|00)[1-9]\\d{0,2}(?: |\\-)?|(?:\\+|00)1\\-\\d{3}(?: |\\-)?)?(0\\d|\\([0-9]{3}\\)|[1-9]{0,3})(?:((?: |\\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\\-)[0-9]{3}(?: |\\-)[0-9]{4})|([0-9]{7}))'
    ),
  },
};

export const passwordSchema = {
  required: {
    message: 'Required',
    value: true,
  },
  // pattern: {
  //   message: '',
  //   value: new RegExp(
  //     '(?=^.{8,64}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*[!-~])(?=.*?\\d)(?=.*?\\W)'
  //   ),
  // },
  maxLength: 64,
};
