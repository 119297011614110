import styled from 'styled-components';

export default styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 90%;
  background: none;

  @media (min-width: 601px) {
    width: 90%;
  }

  @media (min-width: 993px) {
    width: 80%;
  }

  @media print {
    width: 100%;
  }
`;
